import { FindRequest } from "features/requests-details";
import { config } from "config";

type FetchBackendOptions = Parameters<WindowOrWorkerGlobalScope["fetch"]>[1] & {
  token: string;
};

export const fetchBackend = (
  path: Parameters<WindowOrWorkerGlobalScope["fetch"]>[0],
  { token, headers, ...restOptions }: FetchBackendOptions,
) => {
  return window.fetch(`${config.BACKEND_URL}${path}`, {
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...restOptions,
  });
};

export const fetchMAS = (
  path: Parameters<WindowOrWorkerGlobalScope["fetch"]>[0],
  options: Parameters<WindowOrWorkerGlobalScope["fetch"]>[1] = {},
) => {
  return window.fetch(`${config.MAS_URL}${path}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_MAS_TOKEN}`,
    },
    ...options,
  });
};

export const formatProductPrice = (
  minPrice: FindRequest["minPrice"],
  maxPrice: FindRequest["maxPrice"],
) => {
  if (!minPrice && !maxPrice) {
    return "N.C";
  } else if (typeof minPrice === "number" && !maxPrice) {
    return `from $${minPrice.toLocaleString()}`;
  }

  return `$${minPrice?.toLocaleString() ?? 0} - $${maxPrice?.toLocaleString()}`;
};
