import ContentLoader from "react-content-loader";

export function TableContentLoader(props: any) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 1300 150"
      backgroundColor="#f2f2f2"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="336" y="82" rx="0" ry="0" width="2" height="17" />
      <rect x="5" y="10" rx="0" ry="0" width="100%" height="38" />
      <rect x="5" y="58" rx="0" ry="0" width="100%" height="38" />
      <rect x="6" y="105" rx="0" ry="0" width="100%" height="38" />
    </ContentLoader>
  );
}
