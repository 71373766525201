import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { UserIcon } from "@heroicons/react/24/outline";
import classNames from "clsx";

import { formatProductPrice } from "utils";
import { useGetRequestDetails, useGetRequestAssets } from "./api";
import {
  RequestDetailsContentLoader,
  RequestDetailsImagesContentLoader,
} from "./RequestDetailsContentLoader";

const ATTRIBUTE_LIST = [
  { key: "period", label: "Period" },
  { key: "style", label: "Style" },
  { key: "maker", label: "Maker" },
  { key: "designer", label: "Designer" },
  { key: "artist", label: "Artist" },
  { key: "condition", label: "Condition" },
  { key: "color", label: "Color" },
  { key: "material", label: "Material" },
  { key: "origin", label: "Origin" },
  { key: "height", label: "Height" },
  { key: "width", label: "Width" },
  { key: "depth", label: "Depth" },
  { key: "diameter", label: "Diameter" },
  { key: "weight", label: "Weight" },
  { key: "ship_from_country", label: "Ship From Country" },
  { key: "ship_to_country", label: "Ship To Country" },
  { key: "needed_by", label: "Needed by Date", type: "date" },
];

export function RequestDetailsPage() {
  const { requestId } = useParams();
  // TODO: handle case where requestId is not available
  // @see: https://ronati.atlassian.net/browse/TFE-191
  // @see: https://tanstack.com/query/v4/docs/react/guides/suspense#resetting-error-boundaries
  const { request, isLoading: isLoadingRequest } = useGetRequestDetails(
    requestId ?? "",
  );

  const {
    assets,
    isLoading: isLoadingAssets,
    error: assetError,
  } = useGetRequestAssets(request);

  if (!request || isLoadingRequest) {
    return <RequestDetailsContentLoader />;
  }

  return (
    <div>
      <Label>Item</Label>
      <h2 className="text-2xl mb-5">{request.title}</h2>

      <Label>Date</Label>
      <p className="text-sm text-black mb-5">
        {format(new Date(request.creationTimestamp), "dd/MM/yyyy HH:mm")}
      </p>

      <Label>Price range</Label>
      <p className="text-lg mb-5">
        {formatProductPrice(request.minPrice, request.maxPrice)}
      </p>

      <Label>Requesting user</Label>
      <address className="text-lg flex items-center not-italic mb-5 mt-2">
        <div className="bg-aegean-50 rounded-full p-1 mr-2">
          <UserIcon className="h-6 w-6 text-aegean" />
        </div>
        <span>{request.requestor.name}</span>
      </address>

      <Label>Project / Client</Label>
      <p className="text-lg mb-5">{request.reason}</p>

      <Label>Description</Label>
      <p className="text-lg mb-5">{request.description}</p>

      <Label>Attributes</Label>
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-3 gap-3">
        {ATTRIBUTE_LIST.map(({ key, label }) => (
          <li key={key} className="flex flex-col">
            <span className="text-sm">{label}</span>
            <span className="text-lg">
              {request.attributes?.[key] ?? "N/A"}
            </span>
          </li>
        ))}
      </ul>

      <Label className="mt-5">Images</Label>
      <div className="mt-5 flex flex-wrap gap-3">
        {!request.masCollectionId ? (
          <p>No image is available</p>
        ) : isLoadingAssets ? (
          <RequestDetailsImagesContentLoader />
        ) : assetError ? (
          <p className="text-red-500">{assetError.message}</p>
        ) : (
          <>
            {assets?.map(asset => (
              <img
                key={asset.assetId}
                className="w-[49%]"
                src={asset.getUrl}
                alt=""
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

interface LabelProps {
  children: React.ReactNode;
  className?: string;
}

function Label({ children, className }: LabelProps) {
  return (
    <h3
      className={classNames(
        "text-ronatiGrey-800 font-bold text-xs leading-normal",
        className,
      )}
    >
      {children}
    </h3>
  );
}
