import * as React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classNames from "clsx";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Title } from "components";
import ronatiLogo from "./ronati-logo.png";

export function Layout() {
  return (
    <LayoutSkeleton>
      <ErrorBoundary FallbackComponent={LayoutError}>
        <Outlet />
      </ErrorBoundary>
    </LayoutSkeleton>
  );
}

export function LayoutSkeleton({ children }: React.PropsWithChildren) {
  const { logout, user } = useAuth0();

  return (
    <div className="flex flex-col flex-1">
      <header className="sticky top-0 z-30 bg-white px-4 py-6 transition duration-500 sm:px-6 2xl:px-8 border border-b-aegean">
        <div className="flex flex-wrap items-center w-full min-w-0 lg:max-w-none lg:mx-auto 2xl:w-[1300px]">
          <div className="relative flex items-center basis-0 pr-8 pl-5 after:content-[''] after:absolute after:w-[1.5px] after:h-[56px] after:bg-black/[.12] after:right-0">
            <Link to="/" aria-label="Home page" className="min-w-[150px]">
              <img
                src={ronatiLogo}
                alt="Ronati logo"
                className="w-full h-auto"
              />
            </Link>
          </div>

          <div className="flex justify-between items-center flex-grow">
            <h1 className="pl-8 flex items-center">
              <span className="text-xl text-aegean">Inventory Manager</span>
              <span className="inline-block font-bold text-[10px] leading-3 py-0.5 px-1.5 bg-[#e5e5e5] text-[#353535] ml-1.5">
                BETA
              </span>
              <span className="text-coral text-xl ml-10">Sales Portal</span>
            </h1>

            <div className="ml-6 flex items-center">
              <Menu as="div" className="relative ml-3">
                <div className="flex items-center">
                  <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 mr-3">
                    <img src={user?.picture} alt="avatar" />
                  </span>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-lg font-bold focus:outline-none focus:ring-0 pr-5 after:content-[''] after:w-0 after:h-0 after:border after:border-x-4 after:border-t-4 after:border-transparent after:border-t-coral after:absolute after:right-0 after:transition-all ui-open:after:rotate-180">
                    <span className="sr-only">Open user menu</span>
                    <span>{user?.nickname}</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "w-full text-left px-4 py-2 text-sm text-gray-700",
                          )}
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <main id="main-content">
        <div className="w-full min-w-0 flex-auto px-4 py-16 lg:max-w-none lg:mx-auto 2xl:w-[1360px]">
          {children}
        </div>
      </main>
    </div>
  );
}

function LayoutError({ error, resetErrorBoundary }: FallbackProps) {
  const location = useLocation();
  const initialLocationRef = React.useRef(location.pathname);

  React.useEffect(
    function resetErrorBoundaryOnLocationChange() {
      if (location.pathname !== initialLocationRef.current) {
        resetErrorBoundary();
      }
    },
    [location, resetErrorBoundary],
  );

  return (
    <div className="flex flex-col items-center">
      <Title className="mb-5">An error occurred</Title>
      <p className="mb-3">{error.message}</p>
      <button type="button" onClick={resetErrorBoundary} className="btn">
        Retry
      </button>
    </div>
  );
}
