import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { config } from "config";

export function Auth0ProviderWithHistory({
  children,
}: React.PropsWithChildren) {
  const navigate = useNavigate();

  if (!config.AUTH0_DOMAIN || !config.AUTH0_CLIENT_ID) {
    console.error("Set Auth0 domain and Auth0 clientId env var");
    return null;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      redirectUri={config.AUTH0_CALLBACK_URL}
      onRedirectCallback={onRedirectCallback}
      audience={config.AUTH0_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
}
