import { withAuthenticationRequired } from "@auth0/auth0-react";

import { LayoutSkeleton } from "components";

type PropsWithComponentChildren<P = unknown> = P & {
  component: React.ComponentType;
};

export function ProtectedComponent({
  component,
  ...restProps
}: PropsWithComponentChildren) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LayoutSkeleton />,
  });

  return <Component {...restProps} />;
}
