import { Routes, Route } from "react-router-dom";

import { Home } from "./pages/Home";
import { RequestDetailsPage } from "features/requests-details";
import { Layout, LayoutSkeleton } from "./components";
import PageNotFound from "./pages/PageNotFound";
import { ProtectedComponent } from "features/auth/ProtectedComponent";

function App() {
  return (
    <Routes>
      <Route element={<ProtectedComponent component={Layout} />}>
        <Route path="/" element={<ProtectedComponent component={Home} />}>
          <Route
            path="requests/:requestId"
            element={<ProtectedComponent component={RequestDetailsPage} />}
          />
        </Route>
      </Route>
      <Route
        path="callback"
        element={<ProtectedComponent component={LayoutSkeleton} />}
      />
      <Route
        path="/*"
        element={<ProtectedComponent component={PageNotFound} />}
      />
    </Routes>
  );
}

export default App;
