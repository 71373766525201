import * as React from "react";
import classNames from "clsx";
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

Yup.addMethod(Yup.object, "atLeastOneOf", function (...fields) {
  return this.test({
    name: "atLeastOneOf",
    message: "At least one",
    exclusive: true,
    params: { keys: fields.join(", ") },
    test: value => value == null || fields.some(f => !!value[f]),
  });
});

const priceSchema = Yup.object({
  minPrice: Yup.number()
    .typeError("Number required")
    .min(0, "Minimum $0")
    .default(0),
  maxPrice: Yup.number()
    .typeError("Number required")
    .min(Yup.ref("minPrice"), ({ min }) => `Minimum $${min}`),
})
  //@ts-ignore
  .atLeastOneOf("minPrice", "maxPrice");

const requestorSchema = Yup.object({
  requestor: Yup.string().required("Required"),
});

const projectSchema = Yup.object({
  project: Yup.string().required("Required"),
});

const titleSchema = Yup.object({
  title: Yup.string().required("Required"),
});

const dateSchema = Yup.object({
  createdAt: Yup.string().required("Required"),
});

const simpleFilters = [
  { key: "createdAt", label: "Date received", schema: dateSchema },
  { key: "requestor", label: "Requestor", schema: requestorSchema },
  { key: "project", label: "Project", schema: projectSchema },
  { key: "title", label: "Title", schema: titleSchema },
];

export function TableFilters({ onFilter, table }: any) {
  const [showFilters, setShowFilters] = React.useState(false);
  const isFiltering = table
    .getAllColumns()
    .some((col: any) => col.getIsFiltered());

  return (
    <>
      <button
        type="button"
        className={classNames(
          "flex justify-center items-center px-5 py-2 font-medium text-aegean-800 bg-ronatiGrey-400 hover:text-white hover:bg-aegean-400 mt-3",
          {
            "bg-aegean-600 text-white": showFilters,
          },
        )}
        onClick={() => setShowFilters(prev => !prev)}
      >
        {showFilters ? "Close filters" : "Show filters"}
        <AdjustmentsHorizontalIcon width={18} height={16} className="ml-2" />
      </button>

      {showFilters ? (
        <div className="relative mt-5 flex gap-3 flex-wrap">
          <ButtonFilter
            label="Price"
            initialValues={
              table.getColumn("Price").getFilterValue() ?? {
                minPrice: "",
                maxPrice: "",
              }
            }
            validationSchema={priceSchema}
            isFiltering={table.getColumn("Price").getIsFiltered()}
            onFilter={(values: { minPrice: string; maxPrice: string }) =>
              onFilter(
                "Price",
                Object.entries(values).reduce(
                  (acc, [key, value]) => ({
                    ...acc,
                    [key]: value ? Number(value) : undefined,
                  }),
                  {},
                ),
              )
            }
          >
            <div className="flex items-center justify-between">
              <label htmlFor="minPrice" className="flex flex-col w-[45%]">
                From
                <Field
                  type="text"
                  name="minPrice"
                  className="border border-ronatiGrey-600 focus:border-coral-500 focus:ring-coral-500"
                />
                <p className="h-6 text-red-500">
                  <ErrorMessage name="minPrice" />
                </p>
              </label>

              <span className="border-t border-black w-3 h-3 translate-y-1/2"></span>

              <label htmlFor="maxPrice" className="flex flex-col w-[45%]">
                To
                <Field
                  type="text"
                  name="maxPrice"
                  className="border border-ronatiGrey-600 focus:border-coral-500 focus:ring-coral-500"
                />
                <p className="h-6 text-red-500">
                  <ErrorMessage name="maxPrice" />
                </p>
              </label>
            </div>
          </ButtonFilter>

          {simpleFilters.map(filter => (
            <ButtonFilter
              key={filter.key}
              label={filter.label}
              isFiltering={table.getColumn(filter.label).getIsFiltered()}
              onFilter={(obj: any) => onFilter(filter.label, obj[filter.key])}
              initialValues={{
                [filter.key]:
                  table.getColumn(filter.label).getFilterValue() ?? "",
              }}
              validationSchema={filter.schema}
            >
              <label htmlFor={filter.key} className="flex flex-col">
                {filter.label}
                <Field
                  type="text"
                  name={filter.key}
                  className="border border-ronatiGrey-600 focus:border-coral-500 focus:ring-coral-500"
                />
                <p className="h-6 text-red-500">
                  <ErrorMessage name={filter.key} />
                </p>
              </label>
            </ButtonFilter>
          ))}
        </div>
      ) : null}

      {isFiltering ? (
        <div className="flex bg-aegean-50 text-aegean font-bold text-sm px-7 py-3 mt-7 align-center flex-wrap gap-2 sm:gap-0">
          <button
            type="button"
            onClick={() => table.resetColumnFilters()}
            className="mr-7"
          >
            Clear all filters
          </button>

          {table.getColumn("Price").getIsFiltered() ? (
            <RemoveFilterButton
              onClick={() =>
                table.getColumn("Price").setFilterValue(() => undefined)
              }
            >
              Price
            </RemoveFilterButton>
          ) : null}

          {simpleFilters.map(filter =>
            table.getColumn(filter.label).getIsFiltered() ? (
              <RemoveFilterButton
                onClick={() =>
                  table.getColumn(filter.label).setFilterValue(() => undefined)
                }
              >
                {filter.label}
              </RemoveFilterButton>
            ) : null,
          )}
        </div>
      ) : null}
    </>
  );
}

function ButtonFilter({
  onFilter,
  initialValues,
  isFiltering,
  label,
  children,
  validationSchema,
}: any) {
  return (
    <Popover className="lg:relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={classNames(
              "flex justify-center items-center px-5 py-2 font-medium text-black border border-ronatiGrey-600",
              { "border-aegean-200 text-aegean-700": isFiltering },
            )}
          >
            {label}
            <ChevronDownIcon
              width={18}
              height={16}
              className={classNames("ml-2", { "rotate-180": open })}
            />
          </Popover.Button>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute mt-4 left-1/2 -translate-x-1/2 lg:transform-none lg:-left-1/4 bg-white z-10 px-5 pb-7 pt-4 border border-ronatiGray-600 shadow-lg transform w-screen max-w-xs">
              <Formik
                initialValues={initialValues}
                onSubmit={(values, formik) => {
                  onFilter(values);
                  formik.setSubmitting(false);
                  close();
                }}
                validationSchema={validationSchema}
              >
                {({ isValid, dirty }) => (
                  <Form className="flex flex-col justify-center">
                    {children}

                    <button
                      type="submit"
                      className="bg-aegean text-white py-3 px-24 text-sm mt-5 disabled:bg-ronatiGrey-600"
                      disabled={!isValid || !dirty}
                    >
                      Apply filter
                    </button>
                  </Form>
                )}
              </Formik>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function RemoveFilterButton({ onClick, children }: any) {
  return (
    <button
      type="button"
      className="bg-white border border-aegean-400 px-4 py-2 rounded-full flex justify-center items-center gap-x-1 mr-3"
      onClick={onClick}
    >
      {children}
      <XMarkIcon className="h-6 w-6" />
    </button>
  );
}
