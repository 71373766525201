import ContentLoader from "react-content-loader";

export const RequestDetailsContentLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={650}
    height={700}
    viewBox="0 0 650 700"
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="5" y="20" rx="0" ry="0" width="650" height="38" />
    <rect x="5" y="77" rx="0" ry="0" width="162" height="19" />
    <rect x="5" y="116" rx="0" ry="0" width="210" height="38" />
    <rect x="5" y="177" rx="0" ry="0" width="210" height="38" />
    <rect x="6" y="238" rx="0" ry="0" width="210" height="38" />
    <rect x="7" y="298" rx="0" ry="0" width="650" height="137" />
    <rect x="6" y="457" rx="0" ry="0" width="300" height="137" />
    <rect x="350" y="457" rx="0" ry="0" width="300" height="137" />
  </ContentLoader>
);

export const RequestDetailsImagesContentLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={650}
    height={150}
    viewBox="0 0 650 150"
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="6" y="0" rx="0" ry="0" width="300" height="137" />
    <rect x="350" y="0" rx="0" ry="0" width="300" height="137" />
  </ContentLoader>
);
