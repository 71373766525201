import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

import { fetchBackend, fetchMAS } from "utils";
import type { FindRequest, FindRequestResponse, MediaAsset } from "./types";

interface AssetResponseError {
  name: string;
  message: string;
}

export function useGetRequestDetails(requestId: string) {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading } = useQuery<FindRequest>({
    queryKey: ["request", requestId],
    useErrorBoundary: true,
    queryFn: async ({ signal, queryKey }) => {
      const [, id] = queryKey;
      const accessToken = await getAccessTokenSilently();
      const response = await fetchBackend(`/requests/${id}`, {
        token: accessToken,
        signal,
      });

      if (!response.ok) {
        throw new Error("error while fetching request information");
      }

      const { request, ...restResponse } =
        (await response.json()) as FindRequestResponse;

      return { ...restResponse, ...request };
    },
  });

  return { request: data, isLoading } as const;
}

export function useGetRequestAssets(request?: FindRequest) {
  const {
    data: assets,
    isLoading,
    error,
  } = useQuery<MediaAsset[], AssetResponseError>(
    ["request", request?.id, "assets"],
    async ({ signal }) => {
      const response = await fetchMAS(
        `/collection/${request?.masCollectionId}/profile/fullGallery`,
        { signal },
      );

      if (!response.ok) {
        throw new Error("Error while fetching request assets");
      }

      return response.json();
    },
    { enabled: !!request?.masCollectionId },
  );

  return { assets, isLoading, error };
}
