import classNames from "clsx";

export function Title({
  children,
  className,
  ...restProps
}: React.ComponentProps<"h1">) {
  return (
    <h1
      {...restProps}
      className={classNames(
        "text-center text-3xl font-bold tracking-tight text-aegean",
        className,
      )}
    >
      {children}
    </h1>
  );
}
